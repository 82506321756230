<template>
  <div class="flex w-full flex-col">
    <nav class="sticky top-0 z-50">
      <DashboardNavbar />
    </nav>
    <div class="maintenance">
      <img
        src="@/assets/icons/system_maintenance.svg"
        style="padding-bottom: 30px"
      />
      <h1>System maintenance in progress</h1>
      <p>
        We're upgrading Retail media to serve you better. Please check back in a
        few minutes.
      </p>
      <div class="strategies-guidance flex items-center gap-2">
        <img src="@/assets/icons/circle_check.svg" />
        <p class="">Your strategies continue to run as usual</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
}
</script>

<style scoped>
.maintenance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 80px;
  h1 {
    color: #667085;
    font-family: Outfit;
    font-size: 36px;
    padding-bottom: 30px;
  }
  p {
    font-family: Inter;
  }
  .strategies-guidance {
    color: #054f31;
    padding-top: 40px;
  }
}
</style>
