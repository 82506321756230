import {
  difference,
  differenceWith,
  every,
  filter,
  find,
  first,
  flatMap,
  get,
  includes,
  isEqual,
  isNumber,
  isPlainObject,
  map,
  merge,
  omit,
  pick,
  size,
  startsWith,
  toNumber,
  uniq,
} from 'lodash-es'
import { DateNS, type StrategyNS } from '@/typings'
import {
  checkNoLimitValue,
  detachNumberFromString,
  includeKeys,
  isBlank,
  setInputValueByType,
} from './util'
import {
  COMPONENT_TYPE,
  FREQUENCY_TYPE,
  OPERATORS,
  WEEK_FORMAT,
} from '@/constants/common'
import { formatToTimestamp, getWeekDayName } from './day'
import {
  formatCommonNumber,
  formatCurrency,
  formatNumberWithCommas,
  isNumberic,
} from './unit'
import { useAssignedStrategyStore } from '@/store/module/assigned-strategy'
import {
  DEFAULT_CONDITIONS,
  DEFAULT_CONDITION_ITEM,
  DEFAULT_REALLOCATE_CONDITIONS,
  DEFAULT_RULE_STATUS,
  DEFAULT_STATE,
  DEFAULT_STATUS,
  HISTORICAL_ENUMS,
  PERFORMER_ORDER,
  REALLOCATE_ENUM,
  REALLOCATE_METRIC,
  REALLOCATE_OPERETOR,
  RESERVE_STATUS,
  RULE_ACTIONS_ENUMS,
  STRATEGY_RULE_SCHEDULE,
} from '@/constants/strategy'
import { RESERVE_PERIOD_MAP } from '@/configs/date'
import { HISTORICAL_METRIC } from '@/configs/strategy'

const getDefaultConditionItem = (metric: string) => ({
  ...DEFAULT_CONDITION_ITEM,
  metric,
  metricItem: {
    metric,
  },
})
const getConditionInputValue = (
  data: StrategyNS.ConditionProps,
  mainData?: StrategyNS.ConditionProps,
  isCopy?: boolean,
) => {
  const newValue = isCopy
    ? (mainData?.inputValue ?? data.inputValue)
    : data.inputValue
  if (isPlainObject(newValue)) {
    return newValue?.inputPeriod === DateNS.DYNAMIC_DATE.lastXDays
      ? `l${newValue?.inputValue}d`
      : (newValue?.inputValue ?? newValue)
  }
  return newValue
}
const getConditionOperatorValue = (
  data: StrategyNS.ConditionProps,
  mainData?: StrategyNS.ConditionProps,
  isCopy?: boolean,
) => {
  if (isCopy) {
    return mainData && !includes(DEFAULT_CONDITIONS, data.metric)
      ? getReserveOperator(data.metric, mainData?.operatorValue ?? '')
      : data.operatorValue
  }
  return data.operatorValue
}
const filterObjectId = (data: any[]) => filter(data, v => v !== 0)
const isReallocateMetric = (metric: string) =>
  Object.keys(REALLOCATE_METRIC).includes(metric)
export const generateOperatorOption = (data: any): any => ({
  value: Number(data.id),
  label: data.label,
  operator: data.value,
  postFix: data.postFix,
  type: data?.type,
})
export const getReserveOperator = (metric: string, operator: string) => {
  if (operator === OPERATORS.BETWEEN) {
    return REALLOCATE_OPERETOR[REALLOCATE_METRIC[metric]]
  }
  return REALLOCATE_OPERETOR[operator]
}
export const getReserveInputValue = (
  inputVal: any,
  operator: string,
  isSaasUser = false,
) => {
  if (isPlainObject(inputVal)) {
    const { start, end } = inputVal
    if (start && end) return operator === '>' ? end : start
    return isSaasUser
      ? getConditionInputValue({
          ...DEFAULT_CONDITION_ITEM,
          inputValue: inputVal,
        })
      : { ...inputVal }
  }
  return inputVal
}
export const getPerformerLimitOrder = (metric: string, operator: string) => {
  switch (operator) {
    case '>=':
    case '>':
      return PERFORMER_ORDER.LOWEST
    case '<=':
    case '<':
      return PERFORMER_ORDER.HIGHEST
    default:
      return REALLOCATE_METRIC[metric]
  }
}
export const getReserveMainConditions = (
  data: StrategyNS.ConditionProps[],
  sources: StrategyNS.ConditionDataProps[],
) => {
  const reallocateConditions = filter(data, cond =>
    isReallocateMetric(cond.metric),
  )
  if (reallocateConditions.length === 0) {
    const strategyStore = useAssignedStrategyStore()
    const defaultCondInfo = find(sources, [
      'metric',
      DEFAULT_REALLOCATE_CONDITIONS.ROAS,
    ])
    const metricOptions = strategyStore.findRuleByMetric(
      DEFAULT_REALLOCATE_CONDITIONS.ROAS,
    )
    reallocateConditions.push({
      ...getDefaultConditionItem(String(defaultCondInfo?.metric)),
      definitionId: Number(defaultCondInfo?.id),
      operatorValue: '<=',
      inputValue: generateHistoricalInputValue(metricOptions?.options, 0),
    })
  }
  return reallocateConditions
}
export const getFirstCondition = (
  main: StrategyNS.ConditionProps[],
  sources: StrategyNS.ConditionDataProps[],
) => {
  let mainCond = main.find(cond => isReallocateMetric(cond.metric))
  if (!mainCond) {
    const dataCond = sources.find(
      cond => cond.metric === DEFAULT_REALLOCATE_CONDITIONS.ROAS,
    )
    mainCond = {
      ...dataCond,
      ...getDefaultConditionItem(String(dataCond?.metric)),
    }
  }
  return mainCond
}
export const setConditionValues = (
  data: StrategyNS.ConditionProps[],
  currency: string,
  mainData?: StrategyNS.ConditionProps[],
  sourceConditions?: StrategyNS.ConditionDataProps[],
  isSaasUser = false,
  isEditMode = false,
) => {
  if (size(data) === 0) return []
  const lstCondition = map(data, (subItem, subIdx) => {
    const mainInfo = find(mainData ?? [], ['metric', subItem.metric])
    return {
      ...subItem,
      id: Number(subItem?.id),
      definitionId: Number(subItem?.definitionId),
      sortNo: subItem?.sortNo || subIdx + 1,
      inputValue: setInputValueByType(
        getConditionInputValue(subItem, mainInfo, isSaasUser),
        subItem?.type || 'decimal',
        subItem?.metric,
        subItem?.operatorValue,
      ),
      operatorValue: getConditionOperatorValue(subItem, mainInfo, isSaasUser),
      isDisabled: Boolean(subItem?.isDisabled),
      dropDefinitionId: Number(
        subItem?.dropDefinitionId ??
          get(subItem.inputValue, 'dropDefinitionId', 0),
      ),
      dropDefinitionName:
        subItem?.dropDefinitionName ??
        get(subItem.inputValue, 'dropDefinitionName', ''),
      includeToday: Boolean(
        get(subItem.inputValue, 'includeToday', subItem?.includeToday ?? false),
      ),
      currency,
    }
  })
  if (mainData && size(mainData) > 0 && isSaasUser && isEditMode) {
    const reserveMainConditions = getReserveMainConditions(
      mainData,
      sourceConditions ?? [],
    )
    const lstReserveCondition = map(reserveMainConditions, cond => {
      const reserveOperator = getReserveOperator(
        cond?.metric,
        String(cond?.operatorValue),
      )
      const targetOperator = find(cond?.operators ?? [], [
        'value',
        reserveOperator,
      ])
      return {
        ...cond,
        id: 0,
        definitionId: Number(cond?.definitionId),
        operatorValue: reserveOperator,
        operatorName: targetOperator?.label,
        inputValue: setInputValueByType(
          getReserveInputValue(cond?.inputValue, reserveOperator, isSaasUser),
          cond?.type || 'decimal',
          cond?.metric,
          cond?.operatorValue,
        ),
        isDisabled: Boolean(cond?.isDisabled),
        currency,
      }
    })
    return [
      find(lstCondition, c => includes(DEFAULT_CONDITIONS, c.metric)),
      ...lstReserveCondition,
    ]
  }
  return lstCondition
}
export const mapConditionsRule = (data: any[], lstCheckIsRemove?: string[]) =>
  map(data, cond => {
    // const nativeType = getNativeType(String(cond.type), cond.metric)
    // const componentType =
    //   cond.componentType ?? getComponentType(cond.type, cond.operatorValue)
    let { inputValue } = cond
    if (
      cond.operatorValue === OPERATORS.BETWEEN &&
      Array.isArray(cond.inputValue)
    ) {
      const [start, end] = cond.inputValue
      inputValue = { start, end }
    }
    const isRemove = lstCheckIsRemove?.includes(cond.metric) ?? true
    delete cond.inputValue
    return {
      ...cond,
      // type: nativeType,
      inputValue,
      isRemove,
    }
  })
export const getCronsDisplayByType = (type: string, crons: any) => {
  switch (type) {
    case FREQUENCY_TYPE.WEEKLY:
      return map(crons, (item: any) => ({
        dayOfWeek: item.dayOfWeek,
        values: map(item.values, 'timeDisplay'),
      }))
    default:
      return map(crons, item => map(item.values, 'timeDisplay')).flat()
  }
}
export const getNativeType = (type: string, layer: string, metric?: string) =>
  includes(DEFAULT_RULE_STATUS[layer], metric) ? COMPONENT_TYPE.TEXT : type
export const generateScheduleTemplate = (
  schedule: StrategyNS.StrategyScheduleProps,
) => {
  let keyLang = schedule.type
  let paramsLang = {}
  switch (schedule.type) {
    case FREQUENCY_TYPE.WEEKLY:
      paramsLang = {
        lst_weekly: map(schedule.crons, c =>
          getWeekDayName(c.dayOfWeek, WEEK_FORMAT.SHORT),
        )?.join(', '),
      }
      break
    case FREQUENCY_TYPE.DAILY:
      paramsLang = {
        lst_daily: schedule.crons
          ?.map(v => map(v.values, 'timeDisplay'))
          .flat()
          .join(', '),
      }
      break
    default:
      {
        const timeDisplay = Number(
          first<any>(first(schedule.crons)?.values)?.timeDisplay,
        )
        if (schedule.type === FREQUENCY_TYPE.HOURLY) {
          keyLang = timeDisplay === 1 ? 'one_hourly' : 'many_hourly'
          paramsLang = { number: timeDisplay }
        } else if (
          !isBlank(schedule.type) &&
          !includes(FREQUENCY_TYPE, schedule.type)
        ) {
          keyLang = 'empty'
          paramsLang = {}
        } else {
          keyLang = 'minus'
          paramsLang = {}
        }
      }
      break
  }
  return {
    key: `strategy-detail.template-info.options.${keyLang}`,
    params: paramsLang,
  }
}
export const getReviewConditionRule = (conditions: any[]) =>
  conditions.map(cond =>
    omit(cond, ['componentAttrs', 'componentType', 'operatorName']),
  )

export const generateDeletedSchedule = (
  target: any,
  data: any,
  isGenerate = true,
) => {
  let [
    lstTargetCronsId,
    lstDataCronsId,
    lstTargetExcludeDateId,
    lstDataExcludeDateId,
  ]: any = [[], [], [], []]
  if (isGenerate) {
    lstTargetCronsId = filterObjectId(
      map(target?.crons, cr => map(cr.values, crv => Number(crv.id))).flat(),
    )
    lstDataCronsId = filterObjectId(map(data?.crons, cr => Number(cr.id)))

    lstTargetExcludeDateId = filterObjectId(
      map(target?.excludeDates, exl => Number(exl.id)),
    )
    lstDataExcludeDateId = filterObjectId(
      map(data?.excludeDates, exl => Number(exl.id)),
    )
  }

  return {
    lstOldCronsId: merge(lstTargetCronsId, lstDataCronsId),
    lstOldExcludeDateId: merge(lstTargetExcludeDateId, lstDataExcludeDateId),
  }
}
export const generateDeletedRules = (
  target: any[],
  data: any[],
  isGenerate = true,
) => {
  let [lstTargetRuleId, lstDataRuleId]: any = [[], []]
  if (isGenerate) {
    lstTargetRuleId = filterObjectId(map(target, rule => Number(rule.id)))
    lstDataRuleId = filterObjectId(map(data, rule => Number(rule.id)))
  }

  return merge(lstTargetRuleId, lstDataRuleId)
}
const checkFields = (data: any[], fields: string[]) => {
  if (!Array.isArray(data)) {
    return false
  }
  const checkError = data?.map(
    (cr: any) => size(difference(Object.keys(cr), fields)) === 0,
  )
  if (!every(checkError, Boolean)) {
    return false
  }
  return true
}
export const checkApplyTemplate = (tempInfo: any) => {
  if (tempInfo.schedule) {
    if (
      !isBlank(tempInfo.schedule.type) &&
      !includes(FREQUENCY_TYPE, tempInfo.schedule.type)
    ) {
      return false
    }
    if (!checkFields(tempInfo.schedule.crons, ['dayOfWeek', 'values'])) {
      return false
    }
  }
  if (tempInfo.rules) {
    const checkRules =
      tempInfo.rules
        ?.map((rule: any) => {
          const fieldRules = checkFields(tempInfo.rules, [
            'name',
            'sortNo',
            'conditions',
            'actions',
          ])
          const fieldConds = checkFields(rule.conditions, [
            'definitionId',
            'metric',
            'operatorValue',
            'inputValue',
            'type',
          ])
          const fieldActs = checkFields(rule.actions, [
            'definitionId',
            'metric',
            'operatorValue',
            'inputValue',
            'type',
            'reallocate',
          ])

          const typeConds = rule.conditions?.map(
            (cond: any) => !includes(COMPONENT_TYPE, cond.type),
          )
          const typeActs = rule.actions?.map(
            (act: any) => !includes(COMPONENT_TYPE, act.type),
          )

          return merge(fieldRules, fieldConds, fieldActs, typeConds, typeActs)
        })
        ?.flat() ?? []
    if (!every(checkRules, Boolean)) {
      return false
    }
  }
  return true
}
export const formatCurrencyWithPostFix = (
  number: number,
  postFix: string,
  countryCode: string,
  digit = 2,
) => {
  if (postFix === 'currency')
    return `${formatCurrency(number, countryCode, digit)}`
  return `${formatCommonNumber(number, undefined, digit)}${postFix}`
}
export const getOperatorValueByTemplate = (
  tmpId: number,
  ruleIdx: number,
  cond: StrategyNS.ConditionProps,
) => {
  if (
    cond.isDisabled &&
    tmpId > 0 &&
    cond.metric === STRATEGY_RULE_SCHEDULE.STORE_LOCAL_TIME
  ) {
    const strategyStore = useAssignedStrategyStore()
    const { channel, target } = strategyStore.getStrategyData
    const tmpKey = `${channel}_${target.applyTo}`
    const tempInfo = find(strategyStore.templateList[tmpKey], ['id', tmpId])
    const condInfo = find(tempInfo?.rules[ruleIdx]?.conditions, [
      'metric',
      cond.metric,
    ])
    return condInfo?.operatorValue ?? cond?.operatorValue
  }
  return cond?.operatorValue
}
export const getListCheckIsRemoveCondition = (
  rule: StrategyNS.StrategyRuleProps,
) => {
  const lstMainCond = map(rule.conditions, 'metric')
  const lstReallocateCond = map(
    flatMap(map(rule.actions, 'reallocate'), 'conditions'),
    'metric',
  )
  if (map(rule.actions, 'metric').includes(REALLOCATE_ENUM.reallocate)) {
    lstReallocateCond.push(STRATEGY_RULE_SCHEDULE.STORE_LOCAL_TIME)
  }
  return difference(lstMainCond, lstReallocateCond)
}
export const checkDifferenceConditions = (
  main: StrategyNS.ConditionProps[],
  target: StrategyNS.ConditionProps[],
  sources: StrategyNS.ConditionDataProps[],
) => {
  const mainReserve = getReserveMainConditions(main, sources)
  if (size(mainReserve) === 0) {
    return false
  }
  const targetReserve = target.filter(c =>
    Object.keys(REALLOCATE_METRIC).includes(c.metric),
  )

  const newMainReserve = map(mainReserve, m => ({
    ...pick(m, ['metric', 'operatorValue', 'inputValue']),
    operatorValue: getReserveOperator(m.metric, String(m.operatorValue)),
  }))
  const newTargetReserve = map(targetReserve, m =>
    pick(m, ['metric', 'operatorValue', 'inputValue']),
  )
  return (
    size([
      ...differenceWith(newTargetReserve, newMainReserve, isEqual),
      ...differenceWith(newMainReserve, newTargetReserve, isEqual),
    ]) > 0
  )
}
export const generateReallocateForSaas = (
  currency: string,
  main: StrategyNS.ConditionProps[],
  target: StrategyNS.ConditionProps[],
  sources: StrategyNS.ConditionDataProps[],
  isSaasUser = false,
) => {
  let definedListId: number[] = []
  let performerInfo: any = {}
  let reallocateConditions: any = {}
  if (isSaasUser) {
    if (checkDifferenceConditions(main, target, sources)) {
      reallocateConditions = setConditionValues(
        target,
        currency,
        main,
        sources,
        isSaasUser,
        true,
      )
      definedListId = map(
        filter(
          target,
          c => !includes(DEFAULT_CONDITIONS, c.metric) && toNumber(c.id) > 0,
        ),
        c => Number(c.id),
      )
    } else {
      reallocateConditions = setConditionValues(
        target,
        currency,
        main,
        sources,
        isSaasUser,
      )
    }
    const firstCondition = getFirstCondition(main, sources)
    const { definitionId, metric, metricDisplay, operatorValue } =
      firstCondition ?? {}
    performerInfo = {
      metric: String(metric),
      metricDisplay: String(metricDisplay),
      ruleConditionDefinitionId: Number(definitionId),
      orderBy: getPerformerLimitOrder(String(metric), String(operatorValue)),
    }
  } else {
    reallocateConditions = setConditionValues(target, currency, main, sources)
  }
  return {
    definedListId,
    performerInfo,
    reallocateConditions,
  }
}
export const generateHistoricalInputValue = (
  options: any[],
  defaultVal?: any,
  metricPeriod?: string,
) => {
  if (size(options) > 0) {
    // const historicalValue = metricPeriod === DateNS.DYNAMIC_DATE.today ? HISTORICAL_ENUMS.AVERAGE : HISTORICAL_ENUMS.SPECIFIC
    const historicalValue = HISTORICAL_ENUMS.SPECIFIC
    return {
      dropDefinitionId: Number(find(options, ['value', historicalValue])?.id),
      dropDefinitionName: historicalValue,
      inputValue: defaultVal,
      inputPeriod: undefined,
      includeToday: false,
    }
  }
  return defaultVal
}
export const checkHistoricalMetric = (strMetric: any) =>
  includeKeys(HISTORICAL_METRIC, strMetric)
export const checkNumberType = (type: string) =>
  includes([COMPONENT_TYPE.DECIMAL, COMPONENT_TYPE.INTEGER], type)
export const checkHistoricalDropdown = (val: string) =>
  includes([HISTORICAL_ENUMS.CHANNEL_AVERAGE, HISTORICAL_ENUMS.AD_AVERAGE], val)
export const generateHistoricalMetric = (cond: StrategyNS.ConditionProps) => {
  const historicalMetric: StrategyNS.MetricItem = {
    metric: cond.metric,
    inputPeriod: undefined,
    inputValue: undefined,
    includeToday: cond.metricIncludeToday,
  }
  if (Array.isArray(cond.metricPeriod)) {
    historicalMetric.inputPeriod = DateNS.DYNAMIC_DATE.specificDates
    historicalMetric.inputValue = map(cond.metricPeriod, v =>
      formatToTimestamp(v),
    )
  } else if (includeKeys(RESERVE_PERIOD_MAP, cond.metricPeriod)) {
    historicalMetric.inputPeriod = RESERVE_PERIOD_MAP[cond.metricPeriod]
    historicalMetric.inputValue = RESERVE_PERIOD_MAP[cond.metricPeriod]
  } else if (checkHistoricalMetric(cond.metric) && cond.metricPeriod) {
    const matchs = detachNumberFromString(cond.metricPeriod)
    historicalMetric.inputPeriod = DateNS.DYNAMIC_DATE.lastXDays
    historicalMetric.inputValue = Number(first(matchs))
  }
  return historicalMetric
}
export const generateHistoricalByMetric = (
  metric: string,
  model: StrategyNS.MetricItem | any,
) =>
  ({
    metric,
    inputPeriod: model?.inputPeriod ?? DateNS.DYNAMIC_DATE.today,
    inputValue: model?.inputValue ?? DateNS.DYNAMIC_DATE.today,
    includeToday: model?.includeToday ?? false,
  }) as StrategyNS.MetricItem
export const generateStrategyRuleData = (rule: any) => ({
  ...rule,
  conditions: map(rule.conditions, cond => ({
    ...cond,
    metricItem: generateHistoricalMetric(cond),
    metricInputPeriod: RESERVE_PERIOD_MAP[cond.metricPeriod],
  })),
  actions: map(rule.actions, act => ({
    ...act,
    reallocate: {
      ...act.reallocate,
      conditions: map(act.reallocate?.conditions, cd => ({
        ...cd,
        metricItem: generateHistoricalMetric(cd),
      })),
    },
  })),
})
export const generateStrategyDetailByData = (
  data: any,
  isFastCreate = false,
) => {
  const info = omit(data, ['@type'])
  if (!isFastCreate) {
    info.rules = map(info.rules, r => generateStrategyRuleData(r))
  }
  return info as StrategyNS.StrategyDetailProps
}
export const checkRangeHistoricalPeriod = (model: any) => {
  const { inputPeriod, inputValue } = model
  const countValue = Array.isArray(inputValue)
    ? size(uniq(inputValue))
    : Number(inputValue)
  return (
    !includes(
      [
        DateNS.DYNAMIC_DATE.today,
        DateNS.DYNAMIC_DATE.yesterday,
        DateNS.DYNAMIC_DATE.specificDates,
        DateNS.DYNAMIC_DATE.lastXDays,
      ],
      inputPeriod,
    ) || countValue > 1
  )
}
export const getReserveStatus = (main: any) => {
  const { operatorValue, inputValue = 'paused' } = main ?? {}
  let reserve = inputValue
  if (operatorValue === '=') {
    reserve = RESERVE_STATUS[inputValue]
  } else if (includes(['enabled', 'disabled'], inputValue)) {
    reserve = RESERVE_STATUS[RESERVE_STATUS[inputValue]]
  }
  return `change_${reserve}`
}
export const findOperatorByStatus = (operators: any, reserveStatus: any) =>
  find(operators, opt => startsWith(opt.operationKey, reserveStatus))
export const filterActionByReserveStatus = (data: any[], mainStatus: any) => {
  const reserveStatus = getReserveStatus(mainStatus)
  return map(
    filter(
      data,
      item =>
        !includes(RULE_ACTIONS_ENUMS, item.metric) ||
        (includes(RULE_ACTIONS_ENUMS, item.metric) &&
          findOperatorByStatus(item.operators, reserveStatus)),
    ),
    act => ({ value: act.metric, label: act.metricDisplay }),
  )
}
export const displayFormatValue = (
  value: any,
  platformName: string,
  trans: any,
) => {
  const lstNewStatus = { ...DEFAULT_STATUS, ...DEFAULT_STATE }
  if (includeKeys(lstNewStatus, value)) {
    return trans(lstNewStatus[value])
  }
  if (isNumberic(value)) {
    return checkNoLimitValue(value, platformName)
      ? trans('strategy-detail.executed-list.row.no_limit')
      : formatNumberWithCommas(value, 3)
  }
  return value
}
