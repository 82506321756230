<template>
  <el-dropdown class="mr-5">
    <div class="flex items-center gap-2 outline-none">
      <p class="lang">Help</p>
      <el-icon>
        <ArrowDown />
      </el-icon>
    </div>
    <template #dropdown>
      <el-dropdown-menu class="p-0">
        <el-dropdown-item
          v-for="option in helpOptions"
          :key="option.value"
          :command="option.value"
          class="p-0"
        >
          <div
            class="flex w-28 cursor-default items-center justify-between p-2"
          >
            <a
              :href="option.value"
              :class="option.class"
              target="_blank"
              disabled="disabled"
              >{{ option.label }}</a
            >
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script lang="ts" setup>
import { ArrowDown } from '@element-plus/icons-vue'
import { version } from '@/../package.json'

const helpOptions = computed(() => [
  {
    value:
      'https://sites.google.com/intrepid.asia/help-internal-product/getting-started/media-management/retail-media',
    label: 'User guide',
    class: 'help-option',
  },
  {
    value:
      'https://docs.google.com/forms/d/e/1FAIpQLSeCFoRU7XR2VEL8q6ZqlJmSDm4Dn-vfbhnxpy8lAwOq6YYEBg/viewform',
    label: 'Request support',
    class: 'help-option',
  },
  {
    value:
      'https://docs.google.com/document/d/1DetqP3zgwLFxS8OpHuYCACiItB3ON9zNhHGe0x8ZZuo',
    label: 'Release notes',
    class: 'help-option',
  },
  {
    label: `version ${version}`,
    class: 'help-option-disabled',
  },
])
</script>

<style lang="scss">
.lang {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
}

.help-option {
  font-family: Outfit;
  font-size: 13px;
  font-weight: 420;
  line-height: 1.25rem;
}

.help-option-disabled {
  font-family: Outfit;
  font-size: 12px;
  font-weight: 420;
  line-height: 1.25rem;
  pointer-events: none;
  color: #98a2b3;
}
</style>
