<template>
  <el-popover :width="250" popper-style="padding:0">
    <template #reference>
      <el-image :src="user" class="w-6" />
    </template>
    <template #default>
      <div class="flex flex-col items-center px-2 py-1">
        <div
          class="flex w-full flex-col items-center justify-center gap-2 py-3"
        >
          <el-image :src="user" class="w-6" />
          <p class="text-wrap text-center text-base text-gray-600">
            {{ auth0Store.accountInfo.email || 'dev@flywheeldigital.com.cn' }}
          </p>
        </div>
        <div
          class="flex h-10 w-full cursor-pointer items-center gap-2 border-t border-light px-8 py-2 text-base text-regular"
          @click="logout"
        >
          <el-icon :size="16"><Logout /></el-icon>
          <p>Log out</p>
        </div>
      </div>
    </template>
  </el-popover>
</template>

<script lang="ts" setup>
import { useAuth0Store } from '@/store'
import { logout } from '@/utils/auth0'
import user from '@/assets/icons/user.png'
import Logout from '../custom-icons/Logout.vue'

const auth0Store = useAuth0Store()
</script>
