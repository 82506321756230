<template>
  <div
    class="flex h-16 items-center justify-between border-b border-light bg-white px-10"
  >
    <div class="flex items-center gap-16">
      <h2>{{ $t('routes.n.media-management') }}</h2>
      <div class="flex items-center gap-8">
        <RouterLink
          v-for="dashboard in dashboardRoutes"
          :key="dashboard"
          v-slot="{ isActive }"
          :to="{ name: dashboard }"
        >
          <h3
            :class="[
              isActive
                ? 'border-b-2 border-brand-500 pb-3 pt-5 text-brand-500'
                : 'pb-4 pt-5',
            ]"
            class="cursor-pointer"
          >
            {{ $t(`routes.n.${dashboard as string}`) }}
            <i
              v-if="dashboard == 'strategy-assignment-layout' && displayWarning"
              class="warning-icon ml-2"
            />
          </h3>
        </RouterLink>
      </div>
    </div>
    <div class="flex items-center gap-3">
      <HelpPopover />
      <UserPopover />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isNull } from 'lodash-es'
import { CommonNS } from '@/typings'
import { useAssignedStrategyStore } from '@/store/module/assigned-strategy'

const router = useRouter()

const displayWarning = computed(
  () => useAssignedStrategyStore().execActionStrategy.hasDisplayError,
)

const dashboardRoutes = computed(() => {
  const layout = router
    .getRoutes()
    ?.find(_ => _.name === CommonNS.LayoutRouter.DASHBOARD)
  return (
    layout?.children
      .filter(item => isNull(item.meta?.parent))
      .map(item => item.name) || []
  )
})
</script>

<style lang="scss" scoped>
.warning-icon {
  width: 24px;
  height: 24px;
  background-image: url('/icons/warning-column-icon.svg');
  background-repeat: no-repeat;
  position: absolute;
  top: 31px;
}
</style>
