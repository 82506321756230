<template>
  <svg
    data-v-ec96c4c1=""
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    data-darkreader-inline-fill=""
    style="--darkreader-inline-fill: currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M4.5 22.5H13.5C14.3281 22.4992 14.9992 21.8281 15 21V18.75H13.5V21H4.5V3H13.5V5.25H15V3C14.9992 2.17192 14.3281 1.50083 13.5 1.5H4.5C3.67192 1.50083 3.00083 2.17192 3 3V21C3.00083 21.8281 3.67192 22.4992 4.5 22.5ZM15.4395 15.4395L18.129 12.75H7.5V11.25H18.129L15.4395 8.5605L16.5 7.5L21 12L16.5 16.5L15.4395 15.4395Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Logout',
})
</script>
