<template>
  <div class="flex w-full flex-col">
    <nav class="sticky top-0 z-50">
      <DashboardNavbar />
    </nav>
    <div class="unavailable">
      <img
        src="@/assets/icons/system_unavailable.svg"
        style="padding-bottom: 30px"
      />
      <h1>System temporarily unavailable</h1>
      <p>
        Retail media is currently experiencing technical difficulties. Please
        try again in a few minutes.
      </p>
      <div class="strategies-off flex items-center gap-2">
        <img src="@/assets/icons/warning.svg" />
        <p class="">Your strategies are not running</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Unavailable',
}
</script>

<style scoped>
.unavailable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 80px;
  h1 {
    color: #667085;
    font-family: Outfit;
    font-size: 36px;
    padding-bottom: 30px;
  }
  p {
    font-family: Inter;
  }
  .strategies-off {
    color: #7d0a1e;
    padding-top: 40px;
  }
}
</style>
